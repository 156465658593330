<template>
  <div id="app" class="wrapper">
    <div class="height-view" >
      <router-view />
       <vue-progress-bar></vue-progress-bar>
    </div>
  <transition appear v-if="isLoading">
  <div class="height-view" >
      <b-container class="height-view">
        <b-row align-content="center" class="height-view text-center text-danger my-2">
          <b-col
            offset-md="4"
            offset-sm="4"
            offset-lg="4"
            offset-xl="4"
            cols="12"
            md="4"
            sm="4"
            lg="4"
            xl="4"
          >
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </b-col>
        </b-row>
      </b-container>
    </div>
</transition>
  </div>
</template>
<script>
import yaml from 'js-yaml'

export default {
  data () {
    return {
      isLoading: true
    }
  },
  created () {
    this.isLoading = true
    this.loadLanguage('fr')
    this.loadLanguage('de')
  },
  mounted () {
    this.isLoading = false
  },
  computed: {
    locale () {
      return this.$i18n.locale
    }
  },
  methods: {
    loadLanguage (lang) {
      fetch(`/lang/${lang}.yml?version=1`)
        .then(res => res.blob())
        .then(blob => blob.text())
        .then((yamlAsString) => {
          const dataJson = yaml.load(yamlAsString, 'utf8')
          this.$i18n.setLocaleMessage(lang, dataJson)
        })
    }
  }
}
</script>

<style scoped  lang="scss">

.Loading-form {
  text-align: center;
  position: absolute;
  height: 100%;
  z-index: 10000;
  width: 100%;
  font-size: 5em;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  color: #bf1e2e;
  background: white;
}
.wrapper {
  height: 100vh;
}

.height-view {
  height: inherit;
}
</style>
