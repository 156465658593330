<template>
  <footer>
    <b-container fluid>
      <b-row class="row-about-carton">
        <b-col cols="12" class="about-carton">
          <b-img class="ml-md-auto wow bounce" right :src="`${publicPath}${footerImage}`" />
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="about  pt-2  text-right">
      <b-row>
        <b-col cols="12" sm="3" md="3" lg="3" xl="3" class="footer-e-logo mb-4 mb-lg-0 mb-sm-0 mb-md-0 mb-xl-0">
          <a href="https://www.energie-environnement.ch/">
            <b-img left :src="`${publicPath}e_logo_${$i18n.locale}.svg`" />
          </a>
        </b-col>
        <b-col class="footer-end" cols="12" sm="9" md="9" lg="9" xl="9">
          <a :href="$t('pieds_de_page.a_propos_lien')" title="À propos du site" target="_blank">
            {{ $t('pieds_de_page.a_propos') }}
            <img :src="`${publicPath}blasons.png`" alt="Blasons de cantons Suisses" height="28" border="0"
              width="171" />
          </a>
          <a class="social-network-a" href="https://www.youtube.com/channel/UCoBpv_P67OXX1_S7QpYSpUg "><span
              class="fa fa-youtube ml-4"></span></a>
          <a class="social-network-a" href="https://www.facebook.com/energie-environnementch-150262898335854/"><span
              class="fa fa-facebook ml-4"></span></a>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="privacy">
      <a target="_blank" :href="$t('pieds_de_page.politique_de_confidentialite.lien')">
        {{ $t('pieds_de_page.politique_de_confidentialite.titre') }}
      </a>
    </b-container>
  </footer>
</template>
<script>
export default {
  props: {
    footerImage: {}
  },
  mounted() {

  },
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>
<style scoped lang="scss">
@import "./src/styles/mainStyle.scss";

.privacy {
  background: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  padding: 40px 0;
}

.about {
  background: #111;
  margin-top: 0px;
}

.about-carton {
  display: flex;
  align-items: center;

  img {
    width: 10%;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      width: 34%;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {}

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {}

}

.about a {
  color: #9e9c9c;
  @include family-zilla;
}

.row-about-carton span {
  color: white;
  font-size: 3.2em;

  @media only screen and (min-width : 1824px) {
    font-size: 5.2em;
  }
}

.footer-e-logo {
  display: flex;
  align-items: center;
}

.footer-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    @include family-zilla;
  }
}

.vl {
  border-left: 1px solid white;
  height: 20px;
  margin-top: 10px;
}

.social-network-a {
  font-size: 3.2em;
}
</style>
